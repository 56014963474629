<template>
  <v-footer id="home-footer" color="grey darken-4" dark min-height="72" v-if="showFooter">
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <div class="d-flex flex-wrap justify-md-start justify-center justify-md-none">
            <template v-for="(s, i) in social">
              <a
                v-if="true"
                :key="s.name"
                class="white--text pa-1 pa-md-0"
                :href="s.link"
                v-text="s.name"
              />

              <v-responsive
                v-if="i < social.length - 1"
                :key="`divider-${s.name}`"
                class="mx-4 shrink hidden-sm-and-down"
                max-height="24"
              >
                <v-divider vertical />
              </v-responsive>
            </template>
          </div>
        </v-col>
        <v-col class="text-center text-md-right" cols="12" md="6">
          Derechos Reservados &copy; 2020 Monitor Satelital
          {{ $store.getters.Version }}
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "HomeFooter",

  data: () => ({
    social: [
      { name: "Privacidad", link: "/priv" },
      { name: "Terminos y Condiciones", link: "/terms" },
    ],
  }),
  computed: {
    showFooter() {
      return this.$route.meta.footer === undefined ? true : this.$route.meta.footer;
    },
  },
};
</script>

<style></style>
